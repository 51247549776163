import React, { useState } from "react"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css';
import Icon from "../icon"
//import image from "../../assets/images/homepage/la-nostra-comunitat/img-1@3x.png"
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import getMonthName from '../../utils/get-month-name'
import Utils from '../../utils'
import {Link} from 'gatsby'

const OurHistory = (props) =>  {


  const { title, nodes } = props

  const [show, setShow] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalDescription, setModalDescription] = useState('')
  const [modlaImage, setModalImage] = useState('')
  const [modalReadMore, setModalReadMore] = useState('')
  const handleClose = () => setShow(false)

  const handleShow = (title, description, image, url) => {
    setModalTitle(title)
    setModalDescription(description)
    setModalImage(image)
    setModalReadMore(url)
    setShow(true)
  }
  return (
    <VerticalTimeline
    >
      {nodes && nodes.map(({node}) => {
        return(
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(104 57 49)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(104 57 49)' }}
            date={`${node.field_date_day} ${getMonthName(node.field_date_month, node.langcode)} ${node.field_date_year}`}
            iconStyle={{ background: '#683931', color: '#fff' }}
            icon={<Icon />}
            key={node.id}
          >
            <div className="vertical-timeline-image-wrapper">
              <img src={node?.relationships?.field_image?.image_style_uri?._541x359} className="vertical-timeline-element-image" width={'100%'} />
              <Button variant="primary" onClick={() => handleShow(node?.title, node?.body?.value, node?.relationships?.field_image?.image_style_uri?._541x359)}>
                <Icon icon="icons-32-x-32-export-expand" size={24} color="grey-dark" />
              </Button>
            </div>
            <h3 className="vertical-timeline-element-title">{node?.title}</h3>
            <h4 className="vertical-timeline-element-subtitle hide">Miami, FL</h4>
            <p>
              {node?.body?.summary}
            </p>
            <span className="vertical-timeline-cta">
            {
              Utils.isExternal(node?.field_cta?.url)
              ?
              <a
                href={node?.field_cta?.url}
                alt={node?.field_cta?.title}
                target="_blank"
              >
                {node?.field_cta?.title}
              </a>
              :
              <Button
                variant="tertiary"
                onClick={() => handleShow(node?.title, node?.body?.value, node?.relationships?.field_image?.image_style_uri?.wide, node?.field_cta?.url)}
                >
                {node?.field_cta?.title}
              </Button>

            }
           </span>
          </VerticalTimelineElement>
        )
      })
    }

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="timeline-modal-body">
          <img src={modlaImage} alt={modalTitle} />
          <div dangerouslySetInnerHTML={{__html: modalDescription}} />
          {modalReadMore &&
            <div className="know-even-more">
              <Link
                to={Utils.removeCaUrl(modalReadMore)}
                title={modalTitle}
                className="r-more"
              >
                Saber més
              {/*<Icon icon="icons-32-x-32-generic-plus" size={20} color="grey-dark" /> */}
              </Link>
            </div>
          }

          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tancar
          </Button>
        </Modal.Footer>
      </Modal>
    </VerticalTimeline>
  )
}

export default OurHistory
